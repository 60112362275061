import { render, staticRenderFns } from "./adminstration-card-newest.vue?vue&type=template&id=6d1c0940&scoped=true&"
import script from "./adminstration-card-newest.vue?vue&type=script&lang=js&"
export * from "./adminstration-card-newest.vue?vue&type=script&lang=js&"
import style0 from "./adminstration-card-newest.vue?vue&type=style&index=0&id=6d1c0940&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d1c0940",
  null
  
)

export default component.exports
import {QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QIcon})
