
import i18n from '@/vueI18n'
export default {
    props: {
        data: Object,
        number: Number
    },
    setup(props) {

        return {
            props
        }
    }
    ,
    computed: {
        language() {
            return i18n.locale;
        },
    },
    methods: {
        openInNewTab(url) {
            window.open(url, '_blank', 'noreferrer');
        },
    }
}



